
import wc from '../assets/projects/wc.png'
import mch from '../assets/projects/mch.png'
import biznews from '../assets/projects/biznews.png'



export const data2=[

    {
        id:1,
        name:"Mohammad Care Home",
        image:mch,
        github:"#",
        live:"mohammadcarehome.com",
    },
  
   
    {
        id:2,
        name:"5W Communications",
        image:wc,
        github:"#",
        live:"5W Communications",
    },
    
    {
        id:3,
        name:"Biz News Dive",
        image:biznews,
        github:"https://github.com/waduzzaman/real-estate-client",
        live:"5W Communications",
    },
    


]