
import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Skills from "./components/Skills";
import Footer from "./components/Footer.jsx";
import Testimonials from "./components/Testimonials.jsx";
import Works from "./components/Works.jsx";



function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <About />
      <Skills />   
      <Works/>
   
      
      <Testimonials/>
      <Contact />
      <Footer/>
    </div>
  );
}

export default App;