import React from "react";
import { data } from "../data/data.js";
import { data2 } from "../data/data2.js";

const ReactWorks = () => {
  // projects file
  const project = data;
  const project2 = data2;

  return (
    <div name="work" className="w-full text-gray-300 bg-[#0a192f]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        {/* React Projects Section */}
        <div>
          <p className="text-4xl font-bold inline border-b-4 border-pink-600">
            Works
          </p>
          <p className="py-6 text-2xl">
            <span className="inline border-b-2 border-pink-600">React & NextJs</span>{" "}
            Projects
          </p>
        </div>

        {/* React Projects Grid */}
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          {project.map((item, index) => (
            <div
              key={index}
              style={{ backgroundImage: `url(${item.image})` }}
              className="shadow-lg shadow-[#040c16] group container rounded-md 
              flex justify-center text-center items-center mx-auto content-div"
            >
              {/* Hover effect for images */}
              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-2xl font-bold text-white tracking-wider">
                  {item.name}
                </span>
                <div className="pt-8 text-center">
                  <a href={item.github} target="_blank" rel="noopener noreferrer">
                    <button
                      className="text-center rounded-lg px-4 py-3 m-2
                       bg-white text-gray-700 font-bold text-lg"
                    >
                      Code
                    </button>
                  </a>
                  <a href={item.live} target="_blank" rel="noopener noreferrer">
                    <button
                      className="text-center rounded-lg px-4 py-3 m-2
                       bg-white text-gray-700 font-bold text-lg"
                    >
                      Live
                    </button>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* WordPress Projects Section */}
        <div>
          <p className="py-6 text-2xl">
            <span className="inline border-b-2 border-pink-600">WordPress</span>{" "}
            Projects
          </p>

          {/* WordPress Projects Grid */}
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
            {project2.map((item, index) => {
              // Debug log for each item
              console.log("Rendering item:", item, "at index:", index);

              return (
                <div
                  key={index}
                  style={{ backgroundImage: `url(${item.image})` }}
                  className="shadow-lg shadow-[#040c16] group container rounded-md 
                  flex justify-center text-center items-center mx-auto content-div"
                >
                  {/* Hover effect for images */}
                  <div className="opacity-0 group-hover:opacity-100">
                    <span className="text-2xl font-bold text-white tracking-wider">
                      {item.name}
                    </span>
                    <div className="pt-8 text-center">
                      <a href={item.github} target="_blank" rel="noopener noreferrer">
                        <button
                          className="text-center rounded-lg px-4 py-3 m-2
                           bg-white text-gray-700 font-bold text-lg"
                        >
                          Code
                        </button>
                      </a>
                      <a href={item.live} target="_blank" rel="noopener noreferrer">
                        <button
                          className="text-center rounded-lg px-4 py-3 m-2
                           bg-white text-gray-700 font-bold text-lg"
                        >
                          Live
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReactWorks;
