// import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { EffectCoverflow, Pagination } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/effect-coverflow";
// import "swiper/css/pagination";
// import testimonials from "../data/testimonials.json";

// const Testimonials = () => {
//   return (
//     <div name="testimonials" className="w-full py-20 bg-[#0a192f] text-gray-300">
//       {/* Container */}
//       <div className="max-w-[1050px] mx-auto p-4 flex flex-col justify-center w-full h-full">
//         {/* Heading */}
//         <div>
//           <p className="text-4xl font-bold inline border-b-4 border-pink-600">
//             Testimonials
//           </p>
//           <p className="py-4">Here's what our clients have to say</p>
//         </div>

//         {/* Swiper Section */}
//         <Swiper
//           effect="coverflow"
//           grabCursor={true}
//           centeredSlides={true}
//           slidesPerView="auto"
//           coverflowEffect={{
//             rotate: 50,
//             stretch: 0,
//             depth: 100,
//             modifier: 1,
//             slideShadows: true,
//           }}
//           pagination={{
//             clickable: true,
//           }}
//           modules={[EffectCoverflow, Pagination]}
//           className="w-full"
//         >
//           {testimonials.map((testimonial, index) => (
//             <SwiperSlide
//               key={index}
//               className="w-[150px] bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center"
//             >
//               {/* Avatar */}
//               <img
//                 src={testimonial.avatar}
//                 alt={`${testimonial.name}'s avatar`}
//                 className="w-20 h-20 rounded-full shadow-md mb-4"
//               />
//               {/* Name */}
//               <h3 className="text-xl font-semibold text-gray-800">
//                 {testimonial.name}
//               </h3>
//               {/* Designation */}
//               <p className="text-sm text-gray-500">{testimonial.designation}</p>
//               {/* Feedback */}
//               <p className="text-gray-700 italic mt-4">
//                 "{testimonial.feedback}"
//               </p>
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       </div>
//     </div>
//   );
// };

// export default Testimonials;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import testimonials from "../data/testimonials.json";

const Testimonials = () => {
  return (
    <div
      name="testimonials"
      className="w-full py-20 bg-[#0a192f] text-gray-300"
    >
      {/* Container */}
      <div className="max-w-[1050px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        {/* Heading */}
        <div>
          <p className="text-4xl font-bold inline border-b-4 border-pink-600">
            Testimonials
          </p>
          <p className="py-4">Here's what my clients have to say</p>
        </div>

        {/* Swiper Section */}
        <Swiper
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView="auto"
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination]}
          className="w-full"
        >
         {testimonials.map((testimonial, index) => (
  <SwiperSlide
    key={index}
    className="w-[300px] bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center space-y-4"
  >
    {/* Feedback */}
    <p className="text-gray-700 italic font-medium text-lg">
      "{testimonial.feedback}"
    </p>

    {/* Avatar Div (Top) */}
    <div className="flex justify-center items-center mb-4">
      <img
        src={testimonial.avatar}
        alt={`${testimonial.name}'s avatar`}
        className="w-20 h-20 rounded-full shadow-md"
      />
    </div>

    {/* Designation and Company Logo Div (Bottom) */}
    <div className="text-center">
      <p className="text-sm font-semibold text-gray-500">
        {testimonial.designation}
      </p>
      {testimonial.companyLogo && (
        <img
          src={testimonial.companyLogo}
          alt={`${testimonial.name}'s company logo`}
          className="w-12 h-12 mt-2 mx-auto"
        />
      )}
    </div>
  </SwiperSlide>
))}


        </Swiper>
      </div>
    </div>
  );
};

export default Testimonials;
